.customTable table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-bottom: 5px;
  }
  
 .customTable table tbody td {
    border: 2px solid #ddd; 
    padding: 12px;
    text-align: center;
  }

  .customTable table thead th {
    border: 2px solid #ddd; 
    padding: 12px;
    text-align: center;
  }
  
.customTable table thead {
    background-color: #f0f0f0;
  }
  
.customTable table th {
    font-weight: bold;
    background-color: #060606;
    color: #fff;
  }
  
  /* Style alternating rows with a background color */
 .customTable table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
    
  /* Hover effect for rows */
 .customTable table tbody tr {
    background-color: #d8ffd7;
  }
  
  .invoice-detail-heading {
    font-size: 24px;
    margin-bottom: 10px;
    color: #003487;
    text-align: center;
  }
