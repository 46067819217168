body {
    margin: 0;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .oneTable button {
        display: none;
    }
    th.hide-div {
        display: none;
    }
    td.hide-div {
        display: none;
    }
    div {
        display: block !important;
    }

    .flight-gc-data {
        page-break-before: always;
    }

    .second-chart {
        min-width: 100%;
    }

    .first-chart,
    .third-chart {
        min-width: 100%;
        page-break-before: always;
    }

    /* div.map-view {
        page-break-before: always;
    } */
}
